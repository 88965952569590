import * as React from 'react'
import { globalStyles } from '../components/layout.styles'
import { Global } from '@emotion/react'
import * as Scroll from 'react-scroll'
import NavBar from '../components/NavBar6'
import Header from '../components/HeaderMatriculas'
import Pilares from '../components/NossoPilares'
import KingKids from '../components/KingKids'
import KingKSchool from '../components/KingSchool'
import Footer from '../components/Footer'
import SEO from "../components/Seo"
import Share from '../components/Share'

const MatriculasAbertas = () => {
  return (
    <div>
      <Global styles={globalStyles} />
      <SEO title="MATRICULAS ABERTAS | KINGDOM SCHOOL" />
      <NavBar />
      <Scroll.Element>
        <Header />
      </Scroll.Element>
      <Scroll.Element>
        <Pilares />
      </Scroll.Element>
      <Scroll.Element>
        <KingKids />
      </Scroll.Element>
      <Scroll.Element>
        <KingKSchool />
      </Scroll.Element>
      <Footer />
      <Share />
    </div>
  )
}

export default MatriculasAbertas