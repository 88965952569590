import { css, keyframes } from "@emotion/react"

export const header = css`
  background-color: #5416FF;
  font-family: 'Lato', sans-serif;
  padding-top: 120px;
  @media (max-width: 480px) {
    padding-top: 148px;
    padding-bottom: 62px;
  }
`

export const domineARedacao = css`
  background-color: #E8E8DB;
  font-family: 'Source Sans Pro', sans-serif;
  padding-top: 80px;
  @media (max-width: 480px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
`

export const container = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
`

export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	/* margin: 5px 0; */
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const column = css`
  flex: 1;
	margin: 0px;
	padding: 0px;
  /* flex: 1;
	margin: 2px;
	padding: 10px; */
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
      padding: 18px !important;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const content = css`
  h1 {
    font-weight: 900;
    font-size: 72px;
    line-height: 62px;
    color: #5416FF;
    display: flex;
    align-items: center;
    max-width: 470px;
    width: 100%;
    margin: 0;
    margin-bottom: 24px;
    font-family: 'Lato', sans-serif;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    max-width: 470px;
    width: 100%;
    margin: 0;
    margin-bottom: 36px;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #262626;
  }
  @media (max-width: 480px) {
    h1 {
      font-size: 48px;
      line-height: 45px;
    }
    p {
      margin-bottom: 0px;
    }
    img {
      width: 100%;
    }
  }
`

export const content2 = css`
  h1 {
    font-weight: 900;
    font-size: 72px;
    line-height: 62px;
    color: #F8F8F8;
    align-items: center;
    width: 100%;
    margin: 0;
    margin-bottom: 24px;
    font-family: 'Lato', sans-serif;
    span {
      color: #FFC208;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    margin: 0;
    margin-bottom: 36px;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #F8F8F8;
  }
  @media (max-width: 480px) {
    h1 {
      font-size: 48px;
      line-height: 45px;
      text-align: center;
      margin-bottom: 24px;
    }
    p {
      margin-bottom: 0px;
      text-align: center;
    }
    img {
      width: 100%;
    }
  }
`

export const contentPosition = css`
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 60px;
  @media (max-width: 480px) {
    padding: 8px;
    margin-left: 0px;
    a {
      margin-top: 32px;
    }
  }
`

export const desktop = css`
  display: flex;
  @media (max-width: 480px) {
    display: none;
  }
`

export const mobile = css`
  justify-content: center;
  align-items: center;
  display: flex;
  @media (min-width: 480px) {
    display: none;
  }
`

// export const desktop = css`
//   display: flex;
//   @media (max-width: 480px) {
//     display: none;
//   }
// `

export const cloud = css`
  width: 200px; height: 60px;
	background: #fff;
	border-radius: 200px;
	position: relative; 
  &:before, &:after {
    content: '';
    position: absolute; 
    background: #fff;
    width: 100px; height: 80px;
    position: absolute; top: -15px; left: 10px;    
    border-radius: 100px;    
    transform: rotate(30deg);
  }
  &:after {
    width: 120px; height: 120px;
	  top: -55px; left: auto; right: 15px;
  }
`

const moveclouds = keyframes`
  0% {margin-left: 1000px;}
	100% {margin-left: -1000px;}
`

export const cloudItem = css`
  animation: ${moveclouds} 15s linear infinite;
`

