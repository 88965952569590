import * as React from 'react'

import {
  exploreBtn3
} from '../components/styles/Buttons.styles'

const StartNowBtn = ({ children }) => (
  <button css={exploreBtn3}>{ children }</button>
)

export default StartNowBtn
