import * as React from 'react'
import Img from '../images/new/king-kids.png'
import Img2 from '../images/new/king-kids-2.png'
import Cloud from '../images/new/cloud-2.svg'

import {
  column,
  columns,
  container,
  KingKidsStyle,
  content2,
  contentPosition,
  desktop,
  mobile
} from './styles/AgendeUmaVisita.styles'

const KingKids = () => {
  return (
    <div css={[KingKidsStyle, content2]}>
      <img src={Cloud} alt='/' style={{ width: '100%', marginTop: 0 }} />
      <div css={container}>
        <div css={columns}>
          <div css={[column, desktop]}>
            <img src={Img} alt='' style={{ width: '100%' }}  />
          </div>
          <div css={column} style={{ display: 'flex' }}>
            <div css={contentPosition}>
              <h2>Kingdom Kids</h2>
              <h3>Berçário e Educação Infantil</h3>
              <h4>Setor de Habitações Individuais Sul EQL 6/8 Lago Sul, Brasília</h4>
              <p>Somos a primeira instituição de Ensino Infantil a oferecer um ambiente mais profundo no desenvolvimento humano!</p>
              <p>Nos baseamos no Método Glenn Doman para a criação da nossa filosofia. Acreditamos que, ao nascer, a criança já está pronta para ser estimulada. Seguindo essa premissa, nós temos espaços apropriados para a evolução cognitiva e completa do indivíduo.</p>
            </div>
          </div>
          <div css={[column, mobile]}>
            <img src={Img2} alt='' style={{ width: '100%' }}  />
          </div>
        </div>
      </div>
    </div>
  )
}

export default KingKids