import * as React from 'react'
import Button from '../components/StartNowBtn2'
import Img1 from '../images/new/header2.png'
import Cloud from '../images/new/cloud.svg'

import {
  column,
  columns,
  container,
  header,
  content2,
  mobile,
  desktop,
  cloud,
  cloudItem
} from './styles/HeaderMatriculas.styles'
import { Link } from 'gatsby'

const Header = () => {
  return (
    <React.Fragment>
      <div css={[header, content2]}>
        <div css={container}>
          {false && <div css={[cloud, cloudItem]}/>}
          <div css={columns}>
            <div css={column} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <h1>Há <span>10 anos</span> elevando o potencial de cada ser humano.</h1>
              <img css={mobile} src={Img1} alt='' style={{ marginBottom: 32 }} />
              <p>Um método único voltado para o desenvolvimento do seu filho.</p>
              <Link to='/nossa-historia' css={desktop}><Button>EXPLORE A KINGDOM</Button></Link>
            </div>
            <div css={column} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <img css={desktop} src={Img1} alt='' />
              <Link to='/nossa-historia' css={mobile} style={{ margin: 18, width: '100%' }}><Button>EXPLORE A KINGDOM</Button></Link>
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: '#5416FF', marginBottom: '-6px' }}>
        <img style={{ width: '100%' }} src={Cloud} alt='' />
      </div>
    </React.Fragment>
  )
}

export default Header