import * as React from 'react'
import Seguranca from '../images/new/banners/seguranca.svg'
import Afeto from '../images/new/banners/afeto.svg'
import Desenvolvimento from '../images/new/banners/desenvolvimento.svg'
import Bird from '../images/new/icons/bird-2.svg'

import {
  column,
  columns,
  container,
  PilaresStyle,
  content4,
  boxAgendaVisita,
  box1
} from './styles/AgendeUmaVisita.styles'

import {
  formulario2,
  labelAgenda,
  btnEnviar2,
  radioButtons,
  radioFlex
} from '../components/styles/Teste.styles'

const NossosPilares = () => {
  return (
    <div css={[PilaresStyle, content4]}>
      <div css={container}>
        <h2>Nossos pilares</h2>
        <div css={columns}>
          <div css={column} style={{ textAlign: 'center' }}>
            <img src={Seguranca} alt='' style={{ margin: 0, width: 'auto' }} />
            <h3>Segurança</h3>
            <h4>Física e Emocional</h4>
          </div>
          <div css={column} style={{ textAlign: 'center' }}>
            <img src={Afeto} alt='' style={{ margin: 0, width: 'auto' }} />
            <h3>Afeto</h3>
            <h4>Com si mesmo e com o próximo</h4>
          </div>
          <div css={column} style={{ textAlign: 'center' }}>
            <img src={Desenvolvimento} alt='' style={{ margin: 0, width: 'auto' }} />
            <h3>Desenvolvimento</h3>
            <h4>De como ser humano</h4>
          </div>
        </div>
        <div css={box1}>
          <div style={{ textAlign: 'right' }}><img src={Bird} style={{ marginBottom: 0, width: 'auto' }}/></div>
          <div css={boxAgendaVisita}>
            <h2>Agende sua visita!</h2>
            <p>Preencha os dados abaixo e a nossa equipe entrará em contato com você!</p>
            <div css={formulario2}>
              <form method="POST" action="/" data-netlify="true" name="matriculas" style={{ margin: '0 auto' }}>
              <input type="hidden" name="form-name" value="matriculas" />
                <div css={labelAgenda}>
                  <input placeholder='Nome' type="text" name="name" id="name" required />
                </div>
                <div css={labelAgenda}>
                  <input placeholder='E-mail' type="text" name="email" id="email" required />
                </div>
                <div css={labelAgenda}>
                  <input placeholder='Telefone' type="text" name="phone" id="phone" required />
                </div>
                <h3 style={{ margin: '32px 0' }}>Qual o segmento do seu filho?</h3>
                <div css={radioFlex}>
                  <div css={radioButtons}>
                    <input type="radio" id="1" name="segmento"/>
                    <label for="1">Berçário</label>
                  </div>
                  <div css={radioButtons}>
                    <input type="radio" id="2" name="segmento"/>
                    <label for="2">Educação Infantil</label>
                  </div>
                  <div css={radioButtons}>
                    <input type="radio" id="3" name="segmento"/>
                    <label for="3">Ensino Fundamental Anos Iniciais</label>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}><button type="submit" css={btnEnviar2} style={{ marginTop: 56 }}>ENVIAR</button></div>
              </form> 
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NossosPilares