import * as React from 'react'
import Img from '../images/new/king-school.png'
import Img2 from '../images/new/king-school-2.png'

import {
  column,
  columns,
  container,
  KingSchoolStyle,
  content3,
  contentPosition2,
  desktop,
  mobile
} from './styles/AgendeUmaVisita.styles'

const KingKids = () => {
  return (
    <div css={[KingSchoolStyle, content3]}>
      <div css={container}>
        <div css={columns}>
          <div css={column} style={{ display: 'flex' }}>
            <div css={contentPosition2}>
              <h2>Kingdom School</h2>
              <h3>Ensino Fundamental Anos Iniciais</h3>
              <h4>Setor de Habitações Individuais Sul QI 11 área especial B, Entrequadras 02 e 04 - Brasília</h4>
              <p>Possuímos uma filosofia de trabalho ímpar!</p>
              <p>Ensinamos de forma que não subestime a capacidade intelectual de cada um, onde não exista comparação entre eles. Devemos encará-los com seres únicos! Aqui, nossos alunos são desenvolvidos de forma que o cérebro seja constantemente estimulado de maneiras diferentes, permitindo que o interesse seja sempre despertado.</p>
            </div>
          </div>
          <div css={[column, desktop]}>
            <img src={Img} alt='' style={{ width: '100%', marginTop: 48 }}  />
          </div>
          <div css={[column, mobile]}>
            <img src={Img2} alt='' style={{ width: '100%' }}  />
          </div>
        </div>
      </div>
    </div>
  )
}

export default KingKids